*:focus {
    outline: none;
}

h4 {
    font-size: 34px;
}

button {
    text-transform: capitalize !important;
}

.border-between {
    border-bottom: 1px solid #E7E7E7;
}

.border-between:last-child {
    border-bottom: none;
}